/* eslint-disable import/no-anonymous-default-export */
export default {
  items: [
    {
      title: true,
      name: "FLYMYA-V4",
      wrapper: {
        element: "", // required valid HTML5 element tag
        attributes: {}, // optional valid JS object with JS API naming ex: { className: "my-class", style: { fontFamily: "Verdana" }, id: "my-id"}
      },
      class: "", // optional class names space delimited list for title item ex: "text-center"
    },
    {
      name: "Arrangers",
      url: "/arrangers",
      icon: "cui-people",
    },
    {
      name: "Reservations",
      url: "/reservations",
      icon: "cui-list",
    },
    {
      name: "Operation Transactions",
      url: "/operation-transactions",
      icon: "cui-dollar",
    },
    {
      name: "Products",
      url: "/products",
      icon: "cil-basket",
    },
    {
      name: "Tasks",
      url: "/tasks",
      icon: "cil-check",
    },
    // {
    //   name: "Passengers",
    //   url: "/passengers",
    //   icon: "cui-people"
    // },
    {
      name: "Company",
      url: "/company",
      icon: "cil-factory",
    },
    {
      name: "Company Accounts",
      url: "/company-accounts",
      icon: "cil-chart",
    },
    {
      name: "Task Handover",
      url: "/task-handover",
      icon: "cil-chart",
    },
    {
      name: "Currency Calculator",
      url: "/currency-calculator",
      icon: "cui-dollar",
    },
    {
      name: "Translations",
      icon: "cil-language",
      children: [
        {
          name: "Translation List",
          url: "/translation-list",
          icon: "cil-language",
        },
        {
          name: "Translation Model",
          url: "/translation-model",
          icon: "cil-language",
        },
        {
          name: "Translation Language",
          url: "/translation-language",
          icon: "cil-language",
        },
      ],
    },
    {
      name: "Commercial",
      icon: "icon-settings",
      children: [
        {
          name: "Campaigns",
          url: "/commercial/campaigns",
          icon: "cil-braille",
        },
        {
          name: "Promotions",
          url: "/commercial/promotions",
          icon: "cil-library",
        },
        {
          name: "Loyalty Tier",
          url: "/commercial/loyalty-tier",
          icon: "cui-dollar",
        },
        {
          name: "Loyalty Program Milestones",
          url: "/commercial/loyalty-program-milestones",
          icon: "cil-flag-alt",
        },
        // {
        //   name: "Tour Packages",
        //   url: "/commercial/tour-packages",
        //   icon: "cil-library",
        // },
        {
          name: "Incentives",
          icon: "icon-settings",
          children: [
            {
              name: "All incentives",
              url: "/incentives",
              icon: "cil-braille",
            },
            {
              name: "Promo Codes",
              url: "/incentives/promo-code",
              icon: "cil-casino",
            },
            {
              name: "CashBacks",
              url: "/incentives/cashback",
              icon: "cil-casino",
            },
            {
              name: "Coupons",
              url: "/incentives/coupons",
              icon: "cil-cash",
            },
            {
              name: "Arranger Coupons",
              url: "/incentives/arranger_coupons",
              icon: "cil-cash",
            },
          ],
        },
        {
          name: "Email Banner Manager",
          icon: "icon-settings",
          children: [
            {
              name: "Header Banner",
              url: "/email-banner-manager/header-banner",
              icon: "cil-braille",
            },
            {
              name: "Footer Banner",
              url: "/email-banner-manager/footer-banner",
              icon: "cil-braille",
            },
            {
              name: "Default Banner",
              url: "/email-banner-manager/default-banner",
              icon: "cil-braille",
            },
          ],
        },
        {
          name: "Merchandises",
          url: "/commercial/merchandises",
          icon: "cui-dollar",
        },
        {
          name: "Merchandise Order List",
          url: "/commercial/merchandise-order-list",
          icon: "cil-list",
        },
      ],
    },
    {
      name: "Setup",
      icon: "icon-settings",
      children: [
        {
          name: "Arranger Merge",
          url: "/setup/arranger-merge",
          icon: "cui-people",
          children: [
            {
              name: "Arranger Email Merge",
              url: "/setup/arranger-merge/email",
              icon: "cil-list",
            },
            {
              name: "Arranger Phone Merger",
              url: "/setup/arranger-merge/phone",
              icon: "cil-list",
            },
            {
              name: "Arranger ID Merge",
              url: "/setup/arranger-merge/id",
              icon: "cil-list",
            },
          ],
        },
        {
          name: "Airlines",
          url: "/setup/airline",
          icon: "cui-list",
        },
        {
          name: "Blacklist",
          url: "/setup/blacklist",
          icon: "cil-report-slash",
        },

        {
          name: "Client",
          url: "/setup/client",
          icon: "cui-list",
        },
        {
          name: "Feedback",
          url: "/setup/feedback",
          icon: "cil-smile-plus",
        },
        {
          name: "Landmarks",
          url: "/setup/landmark",
          icon: "cil-map",
        },
        // {
        //   name: "Loyalty",
        //   url: "/setup/loyalty-tier",
        //   icon: "cui-dollar",
        // },
        {
          name: "Operators",
          url: "/setup/operators",
          icon: "cui-list",
        },
        {
          name: "Payment Method",
          url: "/setup/payment-method",
          icon: "cui-dollar",
        },
        {
          name: "Product Type",
          url: "/setup/product-type",
          icon: "cui-list",
        },
        {
          name: "Roles",
          url: "/setup/roles",
          icon: "cui-people",
        },
        {
          name: "ShortLink QR",
          url: "/setup/shortlink-qr",
          icon: "cui-list",
        },
      ],
    },
    {
      name: "Finance (WIP)",
      icon: "cil-bank",
      children: [
        {
          name: "Bank Accounts",
          url: "/finance/bank-accounts",
          icon: "cil-tablet",
        },
        {
          name: "Chart Of Accounts",
          url: "/finance/chart-of-accounts",
          icon: "cil-tablet",
        },
        {
          name: "Bank Transactions",
          url: "/finance/bank-transactions",
          icon: "cui-graph",
        },
        {
          name: "Financial Transactions",
          url: "/finance/financial-transactions",
          icon: "cil-calculator",
        },
        {
          name: "Financial Matching Tool",
          url: "/finance/financial-matching-tool",
          icon: "cil-lan",
        },
        {
          name: "Currency",
          url: "/currency",
          icon: "cui-dollar",
        },
        {
          name: "Export Progress",
          url: "/finance/export-progress",
          icon: "cil-tablet",
        },
      ],
    },
  ],
};
